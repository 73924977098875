/* Button styles
--------------------------------------------------------------------*/
/* Ghost Button styles
--------------------------------------------------------------------*/
@media only print {
  .fa::before,
  .fa::after, .more::after, .back::before, .Team__blocks .owl-prev::after,
  .Team__blocks .owl-next::after {
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; }
  .iframe-container, .iframe-container-4-2, .iframe-container-4-3, .iframe-container-16-9 {
    display: none; }
  html, body {
    font-family: Roboto, Arial, Helvetica, "sans-serif";
    font-size: 16px;
    color: #2f373a;
    line-height: 2; } }
  @media only print and (max-width: 700px) {
    html, body {
      font-size: 15px; } }

@media only print {
  html {
    background-color: #11102d; }
  body {
    background-color: #FFF; }
  h1,
  h2,
  h3,
  h4,
  span.h1,
  .Heading--h1,
  .Heading--h2,
  .Heading--h3,
  .Heading--h4 {
    font-family: Asap, Arial, Helvetica, "sans-serif";
    font-weight: 700; }
  p,
  address,
  ol,
  table {
    line-height: 2;
    margin-bottom: 1.5em; }
  h1, span.h1, .Heading--h1 {
    margin-bottom: 0.53333333333em;
    padding-top: 0;
    font-size: 1.875rem;
    line-height: 1.33333333333;
    color: #2f373a; } }
  @media only print and (max-width: 700px) {
    h1, span.h1, .Heading--h1 {
      font-size: 1.7333333333333334rem; } }

@media only print {
  h2, .Heading--h2 {
    margin-bottom: 0.61538461538em;
    padding-top: 0.92307692307em;
    font-size: 1.625rem;
    line-height: 1.53846153846;
    color: #2f373a; } }
  @media only print and (max-width: 700px) {
    h2, .Heading--h2 {
      font-size: 1.4666666666666666rem; } }

@media only print {
  h3, .Heading--h3 {
    margin-bottom: 0.8em;
    padding-top: 1.2em;
    font-size: 1.250rem;
    line-height: 1.6;
    color: #2f373a; } }
  @media only print and (max-width: 700px) {
    h3, .Heading--h3 {
      font-size: 1.2rem; } }

@media only print {
  a {
    -webkit-transition: background 0.3s ease, color 0.3s ease;
    transition: background 0.3s ease, color 0.3s ease;
    color: #1c1b4b;
    text-decoration: underline; }
    a:hover {
      color: #1c1b4b;
      text-decoration: underline; }
  .more {
    font-weight: 700;
    white-space: nowrap; }
    .more::after {
      content: "\f054";
      color: inherit;
      font-size: 0.65em;
      display: inline-block;
      margin-left: 7px; }
    .more:hover {
      text-decoration: underline; }
  .back {
    font-weight: 700;
    white-space: nowrap; }
    .back::before {
      content: "\f053";
      font-size: 0.65em;
      display: inline-block;
      margin-right: 8px; }
    .back:hover {
      text-decoration: underline; }
  blockquote {
    margin: 30px;
    padding: 5px 0 5px 20px;
    border-left: 4px solid #d2d2d2;
    font-style: italic;
    color: #6e6e6e; }
    blockquote p {
      margin: 0;
      padding: 0; }
  .Section-header {
    max-width: 700px;
    margin: 0px auto 24px auto;
    padding-bottom: 24px;
    position: relative; }
    .Section-header::after {
      content: "";
      position: absolute;
      bottom: -1px;
      left: 50%;
      width: 75px;
      height: 2px;
      -webkit-transform: translate(-50%, 0);
      -ms-transform: translate(-50%, 0);
      transform: translate(-50%, 0);
      background-color: #1c1b4b;
      opacity: 0.5; }
    .Section-header h1 {
      text-transform: uppercase;
      margin-bottom: 0.26666666666em; }
    .Section-header p {
      font-size: 1.125em;
      font-weight: 300;
      line-height: 1.77777777778; }
    .Section-header > *:last-child {
      margin-bottom: 0; }
  input, select, textarea, optgroup, form {
    display: none !important; }
  .btn,
  .knop {
    display: none; }
  /* Responsive afbeeldingen in de content
--------------------------------------------------------------------*/
  img.responsive {
    max-width: 100%;
    height: auto; }
  img.responsive-left-10 {
    float: left;
    margin: 9px 40px 40px 0;
    max-width: 10%;
    height: auto; }
  img.responsive-left-20 {
    float: left;
    margin: 9px 40px 40px 0;
    max-width: 20%;
    height: auto; }
  img.responsive-left-30 {
    float: left;
    margin: 9px 40px 40px 0;
    max-width: 30%;
    height: auto; }
  img.responsive-left-40 {
    float: left;
    margin: 9px 40px 40px 0;
    max-width: 40%;
    height: auto; }
  img.responsive-left-50 {
    float: left;
    margin: 9px 40px 40px 0;
    max-width: 50%;
    height: auto; }
  img.responsive-right-10 {
    float: right;
    margin: 9px 0 40px 40px;
    max-width: 10%;
    height: auto; }
  img.responsive-right-20 {
    float: right;
    margin: 9px 0 40px 40px;
    max-width: 20%;
    height: auto; }
  img.responsive-right-30 {
    float: right;
    margin: 9px 0 40px 40px;
    max-width: 30%;
    height: auto; }
  img.responsive-right-40 {
    float: right;
    margin: 9px 0 40px 40px;
    max-width: 40%;
    height: auto; }
  img.responsive-right-50 {
    float: right;
    margin: 9px 0 40px 40px;
    max-width: 50%;
    height: auto; }
  body > * {
    display: none; }
  html body .Content {
    display: block; }
    html body .Content main {
      display: block; }
    html body .Content .Content__right {
      display: none; }
  html.home body main {
    display: block; }
    html.home body main .About-home {
      display: block; }
      html.home body main .About-home .About-home__image {
        display: none; }
  .Startblocks,
  .Listblocks,
  .Startblocks-home,
  .Listblocks-home,
  .Team-home,
  .Counters-home,
  .Reviews-home,
  .Cta-row,
  .News-home,
  .Logo-row,
  .Album,
  .btn,
  .knop,
  .more,
  .Downloads,
  .Header-home,
  .Header-content {
    display: none; }
  .Team-home {
    padding: 80px 0;
    text-align: center;
    overflow: hidden;
    position: relative; } }
  @media only print and (max-width: 1300px) {
    .Team-home {
      padding-right: 70px;
      padding-left: 70px; } }
  @media only print and (max-width: 700px) {
    .Team-home {
      padding-top: 48px;
      padding-bottom: 48px; } }

@media only print {
    .Team-home .owl-carousel {
      position: static; }
    .Team-home .Section-header {
      margin: 0px auto 40px auto;
      padding-bottom: 0; }
      .Team-home .Section-header::after {
        display: none; }
    .Team-home [data-id] {
      -webkit-animation-duration: 1s;
      animation-duration: 1s;
      -webkit-animation-fill-mode: both;
      animation-fill-mode: both; }
  .Team {
    padding: 40px 0; }
    h1 + .Team {
      padding-top: 15px; }
    h2 + .Team {
      padding-top: 15px; }
    h3 + .Team {
      padding-top: 15px; }
    h4 + .Team {
      padding-top: 15px; } }
  @media only print and (max-width: 1300px) {
    .Team__blocks {
      width: auto !important; } }

@media only print {
    .Team__blocks .owl-prev,
    .Team__blocks .owl-next {
      position: absolute;
      top: 50%;
      left: 0;
      width: 50px;
      height: 50px;
      text-align: center;
      line-height: 51px;
      background-color: rgba(0, 0, 0, 0.2);
      -webkit-transition: background 0.3s ease, color 0.3s ease;
      transition: background 0.3s ease, color 0.3s ease; }
      .Team__blocks .owl-prev:hover,
      .Team__blocks .owl-next:hover {
        background-color: rgba(0, 0, 0, 0.7); }
        .Team__blocks .owl-prev:hover::after,
        .Team__blocks .owl-next:hover::after {
          -webkit-transform: translate(-6px, 0);
          -ms-transform: translate(-6px, 0);
          transform: translate(-6px, 0); }
      .Team__blocks .owl-prev::after,
      .Team__blocks .owl-next::after {
        display: inline-block;
        line-height: 51px;
        content: "\f053";
        font-size: 20px;
        color: rgba(255, 255, 255, 0.7);
        -webkit-transition: -webkit-transform 0.3s ease, color 0.3s ease;
        transition: transform 0.3s ease, color 0.3s ease; }
    .Team__blocks .owl-next {
      right: 0;
      left: auto; }
      .Team__blocks .owl-next:hover {
        background-color: rgba(0, 0, 0, 0.7); }
        .Team__blocks .owl-next:hover::after {
          -webkit-transform: translate(6px, 0);
          -ms-transform: translate(6px, 0);
          transform: translate(6px, 0); }
      .Team__blocks .owl-next::after {
        content: "\f054"; }
    .Team__item {
      float: left;
      margin: 0 0 4% 4%;
      background-color: #fff;
      text-align: center;
      width: 30.5%; }
      .Team__item:nth-child(3n+1) {
        clear: both;
        margin-left: 0; } }
    @media only print and (max-width: 600px) {
      .Team__item {
        width: 48%;
        margin: 0 0 4% 4%; }
        .Team__item:nth-child(3n+1) {
          clear: none;
          margin-left: 4%; }
        .Team__item:nth-child(2n+1) {
          clear: both;
          margin-left: 0; } }
    @media only print and (max-width: 400px) {
      .Team__item {
        width: 100%;
        margin: 0 0 40px 0; }
        .Team__item:nth-child(3n+1) {
          clear: none;
          margin-left: 0; }
        .Team__item:nth-child(2n+1) {
          clear: none;
          margin-left: 0; } }

@media only print {
      .Team-home .Team__item {
        width: auto;
        margin: 0;
        clear: none; }
      .fullwidth .Team__item {
        width: 22%; }
        .fullwidth .Team__item:nth-child(3n+1) {
          clear: none;
          margin-left: 4%; }
        .fullwidth .Team__item:nth-child(4n+1) {
          clear: both;
          margin-left: 0; } }
      @media only print and (max-width: 900px) {
        .fullwidth .Team__item {
          width: 30.5%; }
          .fullwidth .Team__item:nth-child(4n+1) {
            clear: none;
            margin-left: 4%; }
          .fullwidth .Team__item:nth-child(3n+1) {
            clear: both;
            margin-left: 0; } }
      @media only print and (max-width: 600px) {
        .fullwidth .Team__item {
          width: 48%;
          margin: 0 0 4% 4%; }
          .fullwidth .Team__item:nth-child(3n+1) {
            clear: none;
            margin-left: 4%; }
          .fullwidth .Team__item:nth-child(2n+1) {
            clear: none;
            margin-left: 0; } }
      @media only print and (max-width: 400px) {
        .fullwidth .Team__item {
          width: 100%;
          margin: 0 0 40px 0; }
          .fullwidth .Team__item:nth-child(3n+1) {
            clear: none;
            margin-left: 0; } }

@media only print {
      .Team__item img {
        width: 100%; }
      .Team__item__info {
        padding: 24px; }
      .Team__item__name {
        margin-bottom: 0.22222222222em;
        font-family: Asap, Arial, Helvetica, "sans-serif";
        font-weight: 700;
        font-size: 1.125rem;
        line-height: 1.6;
        color: #2f373a !important; }
      .Team__item__function {
        line-height: 1.5;
        color: #2f373a !important; }
  .Reviews-home {
    padding: 80px 0;
    text-align: center; } }
  @media only print and (max-width: 700px) {
    .Reviews-home {
      padding: 48px 0; } }

@media only print {
  .Reviews {
    padding: 30px 0; }
    .Reviews__blocks .Reviews-item {
      position: relative;
      text-align: left;
      margin-bottom: 40px; }
      .Reviews__blocks .Reviews-item:last-child {
        margin-bottom: 0; }
      .Reviews-home .Reviews-item {
        width: 45%;
        float: left;
        margin: 0 0 0 5%; }
        .Reviews-home .Reviews-item:nth-child(2n+1) {
          margin-left: 0; } }
      @media only print and (max-width: 1000px) {
        .Reviews-home .Reviews-item {
          width: auto;
          float: none;
          margin: 0 0 40px 0; }
          .Reviews-home .Reviews-item:last-child {
            margin-bottom: 0; } }

@media only print {
      .Reviews__blocks .Reviews-item__image {
        position: absolute;
        top: 10px;
        left: 0;
        width: 90px;
        height: 90px; }
        .Reviews__blocks .Reviews-item__image img {
          width: 100%;
          border-radius: 50%; } }
      @media only print and (max-width: 500px) {
        .Reviews__blocks .Reviews-item__image {
          top: 0;
          left: 50%;
          -webkit-transform: translate(-50%, 0);
          -ms-transform: translate(-50%, 0);
          transform: translate(-50%, 0); } }

@media only print {
      .Reviews__blocks .Reviews-item__content {
        padding-left: 122px; } }
      @media only print and (max-width: 500px) {
        .Reviews__blocks .Reviews-item__content {
          padding-left: 0;
          padding-top: 104px;
          text-align: center; }
          .Reviews__blocks .Reviews-item__content .Ratings__wrapper {
            display: inline-block;
            margin-bottom: 0; } }

@media only print {
      .Reviews__blocks .Reviews-item__review {
        margin-bottom: 0.5em; }
        .Reviews__blocks .Reviews-item__review p {
          margin: 0; }
      .Reviews__blocks .Reviews-item__author {
        clear: both;
        font-family: Asap, Arial, Helvetica, "sans-serif";
        line-height: 1.77777777778em;
        font-weight: 700;
        font-size: 1.125rem; }
        .Reviews__blocks .Reviews-item__author span {
          font-weight: 400;
          color: #6e797c; }
  .News-home {
    padding: 80px 0;
    text-align: center; } }
  @media only print and (max-width: 700px) {
    .News-home {
      padding: 48px 0; } }

@media only print {
  .News {
    padding: 32px 0; }
    .News__blocks {
      padding-top: 8px; }
      .News__blocks .News-item {
        position: relative;
        text-align: left;
        margin-bottom: 40px;
        overflow: hidden;
        color: #2f373a;
        text-decoration: none;
        display: block; }
        .News__blocks .News-item:hover {
          color: #2f373a;
          text-decoration: none; }
        .News-home .News-item {
          width: 47.5%;
          float: left;
          margin: 0 0 5% 5%; }
          .News-home .News-item:nth-child(2n+1) {
            margin-left: 0; }
          .News-home .News-item:nth-last-child(-n+2) {
            margin-bottom: 0; } }
        @media only print and (max-width: 700px) {
          .News-home .News-item {
            width: auto;
            float: none;
            margin: 0 0 40px 0; }
            .News-home .News-item:nth-last-child(-n+2) {
              margin-bottom: 40px; }
            .News-home .News-item:last-child {
              margin-bottom: 0; } }

@media only print {
        .News__blocks .News-item__image {
          float: left;
          width: 23%;
          margin-top: 5px; }
          .News-home .News-item__image {
            width: 33%; } }
          @media only print and (max-width: 1000px) {
            .News-home .News-item__image {
              width: 20%; } }

@media only print {
          .fullwidth .News-item__image {
            width: 20%; }
          .News__blocks .News-item__image img {
            width: 100%; } }
        @media only print and (max-width: 500px) {
          .News__blocks .News-item__image {
            width: 18% !important; } }

@media only print {
        .News__blocks .News-item__content {
          float: left;
          width: 77%;
          -webkit-box-sizing: border-box;
          box-sizing: border-box;
          padding-left: 32px; }
          .News__blocks .News-item__content--no-image {
            width: 100% !important;
            padding-left: 0 !important; }
          .News-home .News-item__content {
            width: 67%; } }
          @media only print and (max-width: 1000px) {
            .News-home .News-item__content {
              width: 80%; } }

@media only print {
          .fullwidth .News-item__content {
            width: 80%; } }
        @media only print and (max-width: 500px) {
          .News__blocks .News-item__content {
            width: 82% !important;
            padding-left: 24px !important; }
            .News__blocks .News-item__content--no-image {
              padding-left: 0 !important; } }

@media only print {
          .News__blocks .News-item__content h2 {
            padding-top: 0;
            font-size: 1.125rem;
            font-weight: 700;
            line-height: 1.77778em; }
          .News__blocks .News-item__content p {
            margin-bottom: 0; }
          .News__blocks .News-item__content .more {
            color: #1c1b4b;
            text-decoration: none; }
            .News__blocks .News-item__content .more:hover {
              color: #1c1b4b;
              text-decoration: underline; }
        .News__blocks .News-item__date {
          font-weight: 400;
          color: #6e797c;
          font-style: italic; }
  .News-detail header {
    margin-bottom: 24px; }
    .News-detail header h1 {
      margin-top: -9px;
      margin-bottom: 0.26666666666em; }
  .News-detail__date {
    font-weight: 400;
    color: #6e797c;
    font-style: italic; }
  .News-detail__button {
    padding-top: 24px; }
    .News-detail__button .btn::after {
      display: none; } }

/*# sourceMappingURL=print.css.map */
